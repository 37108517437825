const getEnv = () => {
	return {
		API_ENDPOINT_URL: 'https://api.tunimeet.com/v1/',
		IMG_SRC: 'https://api.tunimeet.com',
		APP_URL: 'https://admin.tunimeet.com',
		SUBSCRIPTIONKEY: "3O9tNApGd2FU3lybrWcAA9f9UTdIhtj2SwJOp3Rp1IJjYEVMxBIHJQQJ99AKAC5T7U2XJ3w3AAAYACOGacK4"
	};
};

export const env = getEnv();
